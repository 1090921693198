import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'

import Container from '@objects/container'
import NewsList from '@components/newsList'
import ImageCard from '@components/imageCard'
import Card from '@objects/card'
import Copy from '@objects/copy'

export const frontmatter = {
  breadcrumbs: [{ label: 'navigation.media', link: 'media.path' }],
}

const useStyles = makeStyles((theme) => ({
  contactCard: {
    marginBottom: theme.spacing(56),
  },
}))

function PressPage({ data }) {
  const classes = useStyles()

  const intl = useIntl()

  const contactcontent =
    data.contactText?.value?.childMarkdownRemark?.rawMarkdownBody

  const listTitle = data.contentfulArticleList?.title
  const articles = data.contentfulArticleList?.articles
  const heroImage = data.contentfulArticleList?.articleHero
    ?.stageImageWithFocalpoint
    ? data.contentfulArticleList?.articleHero?.stageImageWithFocalpoint.image
    : data.contentfulArticleList?.articleHero?.stageImage
  const heroImageFocalPoint =
    data.contentfulArticleList?.articleHero?.stageImageWithFocalpoint
      ?.focalPoint
  const heroTitle = data.contentfulArticleList?.articleHero?.title
  const heroSlug = data.contentfulArticleList?.articleHero?.slug

  const mappedArticles = articles?.map((article) => {
    return {
      id: article?.id,
      headline: article?.title,
      copy: article?.stageCopy
        ? article?.stageCopy?.childMarkdownRemark?.html
        : article?.teaserCopy?.childMarkdownRemark?.html,
      pdf: article.pdf,
      slug: `${intl.formatMessage({
        id: 'press.article.path',
      })}${article?.slug}/`,
    }
  })

  return (
    <>
      {!!heroTitle && !!heroImage && (
        <ImageCard
          headline={heroTitle}
          subline={intl.formatMessage({
            id: 'article.press.subline',
          })}
          button={{
            label: intl.formatMessage({ id: 'press.read.article' }),
            link: `${intl.formatMessage({
              id: 'press.article.path',
            })}${heroSlug}/`,
            icon: 'TextArrow',
            type: 'secondary',
          }}
          image={heroImage}
          focalPoint={heroImageFocalPoint}
          parallax
        />
      )}

      {!!listTitle && articles?.length > 1 && (
        <Container width="sm"
        ariaLabel="arialabel.presse"
        >
          <NewsList
            itemHeadlineLevel={3}
            items={mappedArticles}
            button={{
              label: intl.formatMessage({ id: 'press.read.all' }),
              link: intl.formatMessage({ id: 'press.article.path' }),
            }}
          />
        </Container>
      )}
      {contactcontent && (
        <Card
          className={classes.contactCard}
          answerborder="none"
          bar="grey"
          barOrientation="left"
          bind="right"
          cardInner="default"
          type="default"
        >
          <Copy markdown={contactcontent}></Copy>
        </Card>
      )}
    </>
  )
}

PressPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default PressPage

export const pageQuery = graphql`
  query PressPageQuery($locale: String!) {
    contentfulArticleList(
      node_locale: { eq: $locale }
      identifier: { eq: "pressemitteilungen" }
    ) {
      title
      articles {
        slug
        title
        teaserCopy {
          childMarkdownRemark {
            html
          }
        }
        pdf {
          title
          file {
            url
            details {
              size
            }
          }
        }
      }
      articleHero {
        stageImage {
          fluid(maxWidth: 2000, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
        stageImageWithFocalpoint {
          image {
            fluid(maxWidth: 2000, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
          }
          focalPoint
        }
        title
        slug
      }
    }

    contactText: contentfulStaticField(
      key: { eq: "press.contact.teaser.text" }
    ) {
      value {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
    }
  }
`
